exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bedankt-tsx": () => import("./../../../src/pages/bedankt.tsx" /* webpackChunkName: "component---src-pages-bedankt-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-voorbeeld-hoofdstuk-tsx": () => import("./../../../src/pages/voorbeeld-hoofdstuk.tsx" /* webpackChunkName: "component---src-pages-voorbeeld-hoofdstuk-tsx" */),
  "component---src-templates-blog-post-archive-tsx": () => import("./../../../src/templates/blog-post-archive.tsx" /* webpackChunkName: "component---src-templates-blog-post-archive-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

